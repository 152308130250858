.ShitContainer {
  position: relative;
  z-index: 2;
  min-height: 100%;
  background: #202020;
  transition: all 0.5 ease;
}

.NavNotification {
  color: #fff;
  background: #ff9925;
  border-top: 1px solid #ffae51;    
  border-left: 1px solid #ffae51;      
  border-right: 1px solid #c57821;
  border-bottom: 1px solid #c57821;
  border-radius: 5px;
  height: 20px;
  width: 20px;
  display:flex;
  justify-content: center;
  align-items: center;
  padding:10px;
  margin-right: 5px;
  margin-top: 9px;
}

.NavHeader {
  position: absolute;
  color: #ff7525; /*#ff9925*/
  font-size: 30px;
  left: 0;
  width: 100%;
  background-color: rgb(26, 26, 26);
  top: 0px!important;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 20px;
  border-bottom: 1px solid #333;
  text-decoration: underline;
}

.NavHeader > span {
  position: relative;
  color: #fff;
  text-decoration: none;
}

.NavDropDown {
  position: relative;
  width: 100%;
}

.NavDropDown > ul > li {
  font-size: 14px!important;
}

.NavDropDown > ul > li > div {
  background-color: rgb(232, 232, 232)!important;
  padding: 5px;
  color: black;
  transition: color 0.3s ease;
}

.NavDropDown > ul > li > div:hover { 
  color: tomato!important;
}

.NavDropDown > ul {
  padding: 0px!important;
}

.NavContainer {
  z-index: 3;
  position: relative;
  max-width: 600px;
  transition: max-width 0.5s ease;
  background: #202020;
}

.NavLeft {
  position: relative;
  z-index: 400;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
}

.NavOverflowContainer {
  width: 100%;
  min-height: 100%;
}

.NavMainenu {
  margin: 0;
  padding-bottom: 14px;
  color: #bbb;
  /*padding-left:15px;*/
  text-align: center; 
}

.NavLeft > ul {
  overflow: hidden;
  padding-left: 0px;
  padding-top: 70px;
  margin: 0;
  min-height: 100%;
  position: relative;
  max-width: 250px;
  background: #202020;
  list-style-type: none;
  -webkit-font-smoothing: antialiased; /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), max-width 0.5s ease;
}

.NavLeft > ul > li{
  flex-direction: row;
  justify-content: center;
  font-size: 17px;
  width: 100%;
  display: flex;
}

.NavLeft > ul > li > a {
  padding-left: 5px;
  margin-right: 5px;
  padding-bottom: 11px;
  padding-top: 11px;
  white-space: nowrap;
  display: block;
  letter-spacing: .1rem;
  width: 100%;
  color: white;
  text-decoration: none;
  font-weight: 350;
  transition: color 0.3s ease;
  min-width: 180px;
}

.NavLeft > ul > li >a:hover {
  color: tomato; 
}

.NavLeft > ul > li > div > a {
  padding-left: 5px;
  margin-right: 5px;
  padding-right: 5px;
  padding-bottom: 11px;
  padding-top: 11px;
  white-space: nowrap;
  display: block;
  letter-spacing: .1rem;
  width: 100%;
  color: white;
  text-decoration: none;
  font-weight: 350;
  transition: color 0.3s ease;
  min-width: 180px;
}

.NavLeft > ul > li > div > a:hover {
  color: tomato; 
}

.NavLeft > input {
  display: block;
  width: 38px;
  height: 32px;
  position: absolute;
  top: 9px;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  transition: all 1s ease;
  -webkit-touch-callout: none;
}

.NavLeft > input:checked ~ div span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #eee;
}

.NavLeft > input:checked ~ div span:nth-last-child(3){
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.NavLeft > input:checked ~ div span:nth-last-child(2){
  transform: rotate(-45deg) translate(0, -1px);
}

.NavLeft > input:checked ~ ul{
  transform: none;
}

.NavLeft > div {
  position: absolute;
  top: 1px;
}

.NavLeft > div > span {
  display: block;
  top: 15px;
  left: 15px;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #ff9925;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 23px -5px; /*36px -14px*/
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

.NavLeft > div > span:first-child{
  transform-origin: 0% 0%;
}

.NavLeft > div > span:nth-last-child(2){
  transform-origin: 16px 6px;
}

@media (max-width: 600px) {
  .ShitContainer {
    position: absolute!important;
  }
}
