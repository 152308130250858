.ComponentsContainer {
  flex: 1;
  margin: 15px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; 
  color: #000;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ComponentsContainerHeaderContent {
  font-size: 22px;
  letter-spacing: 1px;
  padding: 8px 25px 8px 25px;
  background-color:rgb(155, 200, 238);
}

.ComponentsContainerHeader {
  white-space: nowrap;
  background-color:rgb(155, 200, 238);
  color: #222;
  display: flex;
  align-items: center;
  justify-content:space-between;
  text-shadow: 2px 7px 5px rgba(0,0,0,0.1), 
  0px -4px 10px rgba(255,255,255,0.1);
}

.ComponentsContainerContent {
  padding: 25px 25px 25px 25px;
  border-bottom: #ccc;
  max-height: 1400px;
  transition: all 0.1s ease-in-out;
}

.ComponentsContainerContent > form{ 
  display: flex;
  align-items: center;
  justify-content: center;
}

.ComponentsContainerHeaderTools {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ComponentsContainerHeaderTools > img {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}

.ComponentsContainerHeaderTools > img:hover {
  cursor: pointer;
}

.ComponentsContainerHeaderTools > img:nth-child(2) {
  margin-top:3px;
}

@media only screen and (max-width: 500px) {
  .ComponentsContainer {
    margin: 2px;
  }

  .ComponentsContainerContent {
    padding: 8px 8px 8px 8px;
  }
}