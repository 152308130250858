.LoadingContainer {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2000;
  position: fixed;
} 

.Loader {
  z-index: 3000;
  margin: -48px 0 0 -48px;
  position: absolute;
  top: 50%;
  left: 50%;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #ff2020;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 0.7s linear infinite; /* Safari */
  animation: spin 0.7s linear infinite;
  filter: drop-shadow(0 0 10px black);
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg)}
  100% { -webkit-transform: rotate(360deg)}
}

@keyframes spin {
  0% { transform: rotate(0deg)}
  100% { transform: rotate(360deg)}
}