.App {
  /*min-height: 100vh !important;
  min-width: 100vw !important;*/
  scroll-behavior: smooth;
  z-index: -1;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.cursorpointer {
  cursor: pointer;
}

.overflowhidden {
  overflow: hidden;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}