.Header {
  grid-column: 1 / 3;
  color: black;
  text-align: center;
  min-width: 300px;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.6);
}

.HeaderUpperContainer {
  border-bottom: 1px solid #bbb;
  display: flex;
  height: 60px;
  flex-direction: row;
}

.HeaderHeader {
  justify-self: flex-start;
  font-size: 27px;
  padding-left: 55px;
}

.HeaderUpperLeft {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  background: rgb(221, 221, 221);
  color: #222;
  font-weight: 500;
  letter-spacing: 1px;
  height: 100%;
  width: 100%;
}

.HeaderSearchBar {
  background-color: #eee;
  position: relative;
  color: #333;
  font-size: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.HeaderSearchBar > img {
  position: absolute;
  right: 70px;

}

.HeaderSearchBar > img:hover {
  cursor: pointer;
}

.HeaderSearchBar > input {
  color: #222;
  background: #eee;
  max-width: 300px;
  font-size: 18px;
  padding: 6px;
  margin-right: 10px;
  border-radius: 10px;
  outline: none;
  border: none;
  transition: .2s;
}

.HeaderSearchBar > input:focus {
  background-color: #eee;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #ccc;
  outline: none;
  cursor: text!important;
}

.HeaderSearchBar > input:hover {
  cursor:default
}

.HeaderSearchBar > input:focus ~ img{
  display: none;
}

.HeaderSearchBar > span:hover {
  cursor: pointer;
}

.HeaderUppperRight { 
  border-left: 1px solid #bbb;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  white-space: pre-line;
  background: rgb(221, 221, 221);
  padding-left: 5px;
  padding-right: 5px;
  height: 100%;
  color: #333;
  position: relative;
}

.HeaderUppperRight > div { 
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 18px;
}

.HeaderUppperRight > div:nth-child(2) {
  position: absolute;
  bottom: 0;
}

.HeaderLowerContainer {
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  background-color: #eee ;
  border-bottom: 1px solid #bbb;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.HeaderLowerContainer > div:first-child {
  font-size: 11px;
  padding-left: 5px;
  padding-bottom: 5px;
  color: #666;
}