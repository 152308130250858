.MainApp {
  display: grid;
  grid-template-columns: min-content 1fr;
  Font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  margin: 0;
  min-height: 100vh;
  background-color: #e8e8e8; /*eee*/
}

.MainAppContainer {
  height: 100%;
  padding-bottom: 200px;
}

.UserInfo {
  margin-top: -57px;
  background: rgb(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 100px;
  padding: 10px 14px;
}

@media only screen and (max-width: 750px) {
  .MainApp{
    justify-content: flex-start;
  }
  .OverwriteForeground{
    justify-content: flex-start;
    padding-top: 50px; 
  }
  .UserInfo {
    margin: 0px;
  }

}