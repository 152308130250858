.PickupLocationsContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.PickupLocationsContainer form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.PickupLocationsExplanation {
  padding: 15px;
}

.PickupLocationsExplanation p {
  display: table-cell;
  white-space: break-spaces;
}

.PickupLocations {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.PickupLocations input {
  padding: 0;
  margin: 0;
}

.PickupLocationValue {
  width: 80px;
}

.PickupLocationPrice {
  width: 60px;
}

.PickupLocationsTableCell {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 2px;
  display: table-cell;
}

.PickupLocationsTableCell > div{
  overflow-wrap: break-word;
  word-wrap:break-word; 
}

.PickupLocationsTableCell div[contenteditable]:focus {
  outline: 0px solid transparent;
  background-color: rgba(255, 255, 255, 0.397);
}

.PickupLocationsTableCell div[contenteditable] {
  cursor: text;
}

.PickupLocationsOptions p{
  display: inline;
  margin: 0;
  padding: 0;
}

.PickupLocationsOptions p:hover {
  text-decoration: underline;
}
