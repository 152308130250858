.UsersToolsContainer {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  background:rgba(0, 0, 0, 0.7);
  color: #fff;
}

.UsersPages {
  margin-top: -15px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  font-size: 18px;
}

.UsersTools {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.UsersTools p {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.UsersTools pre {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 0 2px 0;
}

.UsersTools input[type="checkbox"] {
  margin: 0 4px 0 0;
}

.UsersTools input[type="text"] {
  word-break: keep-all;
  border-radius: 4px;
  padding: 5px 4px;
  outline: none;
  border: 0;
  background:rgba(255, 255, 255, 0.85);
}

.UsersToolsAnimated {
  transition: max-height 0.15s ease-in-out;
  overflow: hidden;
}

.UsersAddUserContainer {
  display: flex;
  flex-direction: column;
}

.UsersAddUser {
  display: flex;
  flex-direction: column;
}

.UsersAddUser p { 
  margin: 0;
  padding: 0;
}

.UsersAddUserTable {
}

.UsersAddUserTable p {
  margin-top: 5px;
}

.UsersContainer {
  width: 100%;
  display: table;
}

.UsersContainerTableBody {
  display: table-row-group;
}

div.UsersContainerTableRow:not(:first-child) { 
  cursor: pointer;
}

div.UsersContainerTableRow:not(:first-child) > .UsersContainerTableCell{ 
  border-bottom: 1px solid #ddd;
}

div.UsersContainerTableRow:not(:first-child) > .UsersContainerTableCell{ 
  border-bottom: 1px solid #ddd;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 2px;
}

div.UsersContainerTableRow:first-child > .UsersContainerTableCell{ 
  padding-top: 8px;
  padding-bottom: 7px;
  padding-left: 2px;
}

div.UsersContainerTableRow {
  position: relative;
  border-bottom: 2px solid #000;
  display: table-row;
  width: 100%;
  border-collapse: collapse;
  background: rgb(255, 255, 255);
  transition: background .2s ease;
}

div.UsersContainerTableRow:not(:first-child):hover {
  background: rgb(233, 233, 233);
}

.UsersContainerTableRow:nth-child(1) {
  background: rgb(88, 114, 156);
  color: white;
}

.UsersContainerTableCell {
  text-align: center;
  display: table-cell;
  word-break: break-all;
}

.UsersContainerTableCell > div {

}

.UsersContainerTableCell:last-child {
}