.dd-wrapper {
  display: inline;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.dd-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
}

.dd-list {
  z-index: 1;
  display:inline!important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  padding: 5px;
  margin: 0;
  justify-content: space-around;
  border-radius: 5px;
}

.dd-list li {
  list-style-type: none;
  white-space: nowrap;
  width: 100%;
}

.dd-list-item {
  position: relative;
  min-width: 120px;
}

.dd-list li:first-of-type button {
  border-top: 1px solid #ccc;
}

.dd-list li:last-of-type button {
  border-bottom: 1px solid #ccc;
}

.dd-list li button {
  z-index: 10;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 0;
  width: 100%;
  text-align: left;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px;
  font-size: 14px;
}

.dd-list li button:hover {
  cursor: pointer;
  background-color: #ccc;
}

.dd-list li div:hover {
  cursor: pointer;
  background: #ddd;
}

.dd-list li div {
  z-index: 10;
  background: #fff;
  transition: all 0.1s ease-in-out;
  padding: 5px;
}