.ChangePasswordContainer {
  display: flex;
  flex-direction: column;
}

.ChangePasswordContainer input[type="password"]:focus {
  outline: none;
  border: 1px solid #555;
}

.ChangePasswordContainer input[type="password"] {
  width: 300px;
  border: 1px solid #888;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px;
  outline: none;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ChangePasswordContainer input[type="text"] {
  width: 300px;
  border: 1px solid #888;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px;
  outline: none;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ChangePasswordContainer h2 {
  margin: 0;
  text-align: center;
}

.ChangePasswordContainer p {
  margin: 10px 0 5px 0;
}

.PasswordSaveButton {
  margin-top: 20px;
  width: 100px;
  height: 30px;
  align-self: center;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  background-color: rgb(226, 226, 226);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.PasswordSaveButton:active {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}

.ChangePasswordForm {
  display: flex;
  align-self: center;
  justify-content: center;
}

.PasswordButtonContainer {
  display: flex;
  justify-content: space-evenly;
}

.PasswordError {
  padding: 5px 10px;
  border-radius: 6px;
  margin-top: -33px;
  margin-left: 10px;
  position: absolute;
  background-color: rgba(255, 136, 136, 0.9)
}

.PasswordError p {
  margin: 0;
}

.PasswordError p:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: rgba(255, 136, 136, 0.9) transparent;
  border-width: 10px 10px 0;
  bottom: -10px;
  left: 16%;
  margin-left: -10px;
}