.ViewOfferContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: rgb(131, 178, 211);
}

.ViewOffer > h2 {
  padding: 0;
  margin: 0;
  font-size: 20px;
}

.ViewOffer > h1 {
  align-self: center;
}

.ViewOfferContact {
  padding-bottom: 10px;
  padding-top: 10px;
}

.ViewOffer {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #555;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ViewOfferFieldContainer {
  white-space: nowrap;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  flex: 1 1 50%;
  width: 100%;
  flex-wrap: wrap;
}

.ViewOfferFieldContainerServices {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  flex: 1 1 50%;
  width: 100%;
}

.ViewOfferContact {
  display: flex;
  flex-wrap: wrap;
}

.ViewOfferHeader {
  white-space: nowrap;
  padding-right: 3px;
}

.ViewOfferField {
  white-space: nowrap;
}

.CustomerWantAccountCont {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.CustomerWantAccount {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.CustomerWantAccount > div {
  flex: 1 1 30%;
}

.CustomerWantAccount > div > label {
  font-weight: 700;
}

.ViewOfferButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
} 

.GeneralMessaasdasdasdasdasd {
  margin-top: 0px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  background-color: rgb(226, 226, 226);
  width: 70px;
  margin: 0;
  border-radius: 3px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.ViewOfferButton {
  border: 1px solid #777;
  color: #000;
  cursor: pointer;
  font-weight: 500;
  margin-top: 15px;
  width: 250px;
  height: 40px;
  font-size: 18px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgb(255, 255, 255);
}


.ViewOfferComment {
  width: 100%;
  height: 80px;
  font-size:14px;
  border: none;
  resize:none;
  outline: none;
  border: 1px solid #ccc;
}

.ViewOfferComment:focus {
  outline: none;
}

.ViewOfferCommentHeader {
  padding-top:5px;
  padding-bottom: 5px;
  margin: 0;
}

.ViewOfferCustomerComment {
  resize:none;
  outline: none;
  width: 100%;
  height: 80px;
}

.ViewOfferCustomerCommentCont {
  display: flex;
  flex-direction: column;
  width: 100%;
}