.CalendarHighlightRed {
  background-color: rgb(255, 68, 68);
}

.CalendarHighlightYellow {
  background-color: rgb(255, 255, 68);
}

.CalendarNormal {
  background-color: #fff;
}

.CalendarHighlightYellow, .CalendarHighlightRed, .CalendarNormal {
  color: #000;
  border: none;
}

.CalendarClass > div{
  background-color: #fff;
  border: none;
}

.OpenCalendarDay {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.react-calendar__navigation > button {
  border: none;
  background-color: #fff;
}

.react-calendar__month-view__weekdays > div > abbr {
  overflow: hidden;
  max-width: 2ch;
  text-overflow: clip;
}

.WinterStorageFormContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.WinterStorageFormContainer > div {
  flex: 1 1 120px;
  border: 1px solid #666;
}

.WinterStorageButtons form {
  display: flex;
  justify-content:space-evenly;
  padding-left: 5px;
  padding-right: 5px;
  flex-direction: column;
}

.WinterStorageButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:space-evenly;
}

.WinterStorageRealContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: space-around;
}

.WinterStorageMakeOffer {
  display: flex;
  border: 1px solid #666;
  flex-direction: column;
  flex: 1 1 120px;
}

.WinterStorageMakeOffer div{
  display: flex;
  flex-direction: column;
}

.WinterStorageMakeOfferPrice {
  width: 110px;
  outline: none;
  font-size: 16px;
}

.WinterStorageMakeOfferComment {
  resize: none;
  height: 80px;
  margin-bottom: 5px;
  outline: none;
}

.WinterstorageCont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.WinterStorageMakeOfferCommentCustomer {
  resize: none;
  height: 200px;
  margin-bottom: 5px;
  outline: none;
}

.WinterStorageRealContainer > h4 {
  padding: 0;
  margin: 0;
  margin-top: -10px;
  font-size: 18px;
  align-self: center;
}

.WinterStorageRealContainer > p {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
}

.WinterStorageMakeOffer > h4{
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 18px;
}

.WinterStorageFormContainer > div > h4 {
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 18px;
}

.WinterStorageFormContainer > div {
  padding: 5px;
}

/*@media only screen and (min-width: 700px) {
  .WinterStorageFieldValue {
    padding: 5px 5px 5px 5px;
    font-size: 18px;
  }
  .WinterStorageFieldClickable {
    padding: 5px 5px 5px 5px;
    font-size: 18px;
  }
  .WinterStorageFormField {
    padding: 5px 5px 5px 5px;
    font-size: 18px;
  }
  .WinterStorageMakeOffer {
    font-size: 18px;
  }
  .WinterStorageMakeOfferPrice {
    font-size: 18px;
  }
}*/

/*.WinterStorageFormField {
}*/

.WinterStorageFieldValue {
  border-radius: 3px;
}

.WinterStorageFieldClickable { 
  border-radius: 3px;
  cursor: pointer;
  background-color: rgba(159, 152, 255, 0.5);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.WinterStorageFieldClickable, .WinterStorageFieldValue{
  padding: 2px 3px 3px 5px;
}

.WinterStorageFieldValue[contenteditable="true"], .WinterStorageFieldClickable {
  border: 1px solid #666;
}

.WinterStorageFieldValue[contenteditable="true"] {
  outline: 0px solid transparent;
  cursor: text;
  background-color: rgb(255, 242, 130);
}

.WinterStorageSaveButton {
  width: 220px!important;
}

.WinterStorageFieldErrorCont {
  position: relative;
  height: 0;
  overflow:visible;
}

.WinterStorageFieldError  {
  font-weight: 500;
  border-radius: 5px;
  white-space:pre-line;
  padding: 5px;
  position: absolute;
  background-color: rgba(255, 38, 38, 0.85);
  bottom: -10px;
}

.WinterStorageFieldError:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: rgba(255, 38, 38, 0.85) transparent;
  border-width: 10px 10px 0;
  bottom: -10px;
  left: 16%;
  margin-left: -10px;
}