.GeneralMessageContainer {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.21);
  position: fixed;
  backdrop-filter: blur(1px);
}

.GeneralMessageAnimate {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  transition: all .07s ease-in-out;
  overflow: hidden;
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.GeneralMessageShow {
  transform: translate(-50%, -50%) scale(1);
}

.GeneralMessageUpper {
  font-size: 18px;
  height: 36px;
  background-color: #55aa67;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  border-bottom: 1px solid #ccc;
}

.GeneralMessageLower {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.GeneralMessageText {
  display: flex;
  white-space: pre-line;
  padding-top: 10px;
  padding-bottom: 15px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.GeneralMessagesButtons {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.GeneralMessage {
  max-width: 400px;
  background-color: rgba(255, 255, 255);
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  min-width: 200px;
}

.GeneralMessageButton {
  margin-top: 0px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  background-color: rgb(226, 226, 226);
  width: 70px;
  margin: 0;
  border-radius: 3px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.GeneralMessageButtonError {
  background-color: #ff180f;
}