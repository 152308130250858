.Footer {
  position: absolute;
  background: #202020;
  width: 100%;
  height: 100px;
  left: 0;
}

.FooterContainer {
  height: 100px;
  width: 100%;
  position: relative;
  background: #202020;
  height: 100px;
}