.LoginError {
  position: absolute;
  color: #aa0404;
  font-size: 14px;
  margin-top: -15px;
}

.LoginErrorContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PictureLeft {
  width: 700px;
  height: 600px;
  flex: 1 1 20em;
  background-image: url(../Assets/login_bg2.jpg);
  background-size: cover;
  background-position: center;
}

.PictureRight {
  background-image: url(../Assets/venekeskus_logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 90px;
  z-index: 10;
}

.LoginWindowBackground {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #ddd;
}

.LoginWindowContainer {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 1000px;
}

.LoginWindow {
  overflow: hidden;
  display:flex;
  background-color: #f3f3f3;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.LoginWindow label {
  color: #555;
  font-size: 16px;
  margin: 0px 10px;
}

.LoginWindow form {
  margin: 31px 35px 31px 35px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 500px;
  min-width: 300px;
}

.LoginWindow input[type="text"], .LoginWindow input[type="password"] {
  font-size: 16px;
  border-radius: 1px;
  outline: none;
  border: 1px solid #ccc;
  margin: 8px 10px 18px 10px;
  padding: 5px 15px;
}

.LoginWindow input[type="submit"] {
  width: 100px;
  margin: 0 auto;
  outline: none;
  border: none;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  padding: 6px;
  font-size: 14px;
}

.LoginWindow input[type="submit"]:hover {
  cursor: pointer;
}

.LoginWindowTextContainer {
  max-width: 300px;
  align-self: center;
  padding: 10px;
}

.LoginWindowLang {
  align-self:flex-end;
  justify-self: flex-end;
  float:right;
  display: flex;
  padding-top: 5px;
  padding-right: 15px;
  margin: 0;
  font-size: 14px;
}

.LoginWindowLang:hover {
  cursor: pointer;
  text-decoration: underline;
}

.LoginWindowTextContainer h4, .LoginWindowTextContainer p {
  margin: 0;
  text-align: center;
}

.LoginWindow > form > div > div{
  display: flex;
  flex-direction: column;
}

.LoginWindow > form > p {
  text-align: center;
  font-size: 12px;
  color: #aa0404;
}

.LoginWindow > form > p:hover {
  text-decoration: underline;
  cursor: pointer;
}

.LoginWindow div > form pre {
  white-space: pre-wrap;      /* CSS3 */   
  white-space: -moz-pre-wrap; /* Firefox */    
  white-space: -pre-wrap;     /* Opera <7 */   
  white-space: -o-pre-wrap;   /* Opera 7 */    
  word-wrap: break-word;      /* IE */
}

@media only screen and (max-width: 1000px) {

  .PictureLeft {
    display: none;
  }

}

@media only screen and (max-height: 500px) {

  .PictureLeft {
    display: none;
  }

  .LoginWindowContainer {
    display: inline;
    position: static;
  }

  .LoginWindow {
    justify-content: center;
    height: 100vh;
    border-radius: 0;
  }

  .PictureRight {
    display: none;
  }

}

