.Chart1 {
  background: linear-gradient(225deg, rgba(141,233,255,1) 0%, rgba(58,170,198,1) 49%, rgba(0,94,117,1) 100%);
  flex: 1;
  display: flex;
  margin-right:10px;
}

.StatsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #fff;
}

.StatsContainer > div:not(:nth-child(1)) {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  margin-left: 10px;
}

.StatsContainer > div:not(:nth-child(1)) > div:nth-child(1) {
  margin-right: 10px;
  flex:1;
  display: flex;
  flex-direction: column;
}

.StatsContainer > div:not(:nth-child(1)) > div:nth-child(2) {
  margin-left: 10px;
  flex:1;
  display: flex;
  flex-direction: column;
}

.StatsContainer > div:not(:nth-child(1)) > div > div{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.StatsContainer > div:not(:nth-child(1)) > div > div > div:nth-child(1){
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  font-size: 32px;
  width: 100%;
  height: 100%
}

.StatsContainer > div:not(:nth-child(1)) > div > div > div:nth-child(2){
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 2px;
  width: 100%;
  font-size: 14px;
  font-weight: 100;
}

.StatsContainer > div:not(:nth-child(1)) > div:nth-child(2) > div:nth-child(2) { /* UPPE VÄNSTER*/
  background: linear-gradient(225deg, rgba(150,150,150,1) 0%, rgba(85,85,85,1) 49%, rgba(45,45,45,1) 100%);
  margin-top: 10px;
}

.StatsContainer > div:not(:nth-child(1)) > div:nth-child(1) > div:nth-child(2) {/* UPPE HÖGER*/
  background: linear-gradient(225deg, rgba(150,150,150,1) 0%, rgba(85,85,85,1) 49%, rgba(45,45,45,1) 100%);
  margin-top: 10px;
}

.StatsContainer > div:not(:nth-child(1)) > div:nth-child(2) > div:nth-child(1) {/* NERE VÄNSTER*/
  background: linear-gradient(225deg, rgba(208,157,49,1) 0%, rgba(159,107,17,1) 49%, rgba(173,127,0,1) 100%);
  margin-bottom: 10px;
}

.StatsContainer > div:not(:nth-child(1)) > div:nth-child(1) > div:nth-child(1) {/* NERE HÖGER*/
  background: linear-gradient(225deg, rgba(130,208,49,1) 0%, rgba(73,159,17,1) 49%, rgba(56,173,0,1) 100%);
  margin-bottom: 10px;
}

.StatsContainer > div > div > div {
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.849); 
  white-space:normal;
  text-align: center;
  flex:1;
}

.StatsContainer > div{
  height: 260px;
}