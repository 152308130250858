.SilentMessageContainer {
  z-index: 1000;
  position: absolute;
}

.SilentMessage {
  transition: top 0.2s linear;
  padding: 5px 15px 8px 15px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center;
  flex-direction: row;
  white-space: nowrap;
  font-weight: 500;
}

.SilentMessageCloseButton {
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px 7px 3px 5px;
  background-color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 2px;
}

.SilentMessageCloseButton:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}